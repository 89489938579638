<template>
  <div :style="styleObjGdWrapper">
    <el-amap
      ref="map"
      vid="amapDemo"
      :amap-manager="amapManager"
      :center="mapCenter"
      :zoom="zoom"
      :events="events"
    >
      <el-amap-marker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :events="marker.events"
      ></el-amap-marker>
    </el-amap>
  </div>
</template>

<script>
/* eslint-disable */
import { amapManager } from "vue-amap";
export default {
  props: {
    width: {
      default: "100%"
    },
    height: {
      default: "300px"
    },
    location: {},
    poiId: ''
  },
  data() {
    return {
      //地图标记
      markers: [],
      amapManager,
      zoom: 12,
      mapCenter: [121.59996, 31.197646],
      events: {
        init: () => {},
        moveend: () => {},
        zoomchange: () => {}
      },
      timeout: null
    };
  },
  computed: {
    styleObjGdWrapper() {
      return {
        width: this.width,
        height: this.height
      };
    }
  },
  watch: {
    location(val) {
      this.getLocation(val)
    },
    poiId() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.timeout = setTimeout(() => {
        this.getLocationByPoiId()
      }, 500)
    }
  },
  mounted() {
    this.markers = [];
    this.getLocationByPoiId()
  },
  methods: {
    addMarker(lnglat) {
      let marker = {
        position: lnglat,
        events: {}
      };
      this.markers = [marker]
    },
    getLocation(location) { // 根据地址 定位
      if (!location) return
      console.log(location)
      this.markers = []
      AMap.plugin('AMap.Geocoder', () => {
        var geo = new AMap.Geocoder()
        geo.getLocation(location, (status, result) => {
          console.log('getLocation', status, result)
          if (result.geocodes && result.geocodes[0]) {
            let lnglat = result.geocodes[0].location
            this.mapCenter = [lnglat.lng, lnglat.lat]
            this.addMarker(this.mapCenter)
          }
        })
      })
    },
    getLocationByPoiId() { // 根据POI id 定位
      console.log(this.poiId)
      this.markers = []
      AMap.plugin('AMap.PlaceSearch', () => {
        var placeSearch = new AMap.PlaceSearch();
        placeSearch.getDetails(this.poiId, (status, result) => {
          console.log('PlaceSearch', status, result)
          if (status === 'complete' && result.info === 'OK') {
            const poiList = result.poiList
            const pois = poiList.pois
            console.log(pois)
            if (pois && pois[0]) {
              let lnglat = pois[0].location
              this.mapCenter = [lnglat.lng, lnglat.lat]
              this.addMarker(this.mapCenter)
            }
          }
        });
      })
    }
  }
}
</script>

<style>

</style>