<template>
  <div class="page-content" v-loading="loading || detailLoading">
    <div class="hotel-title">
      <span class="icon-hotel"></span>
      {{ $route.query.id ? '编辑门店' : '新增门店' }}
    </div>
    <div class="page-sub-title">门店信息</div>
    <div class="flex">
      <div class="flex-form flex flex-wrap">
        <div class="form-item" v-if="!detailLoading">
          <div class="form-label">
            <span class="must">*</span>
            省市区
          </div>
          <el-cascader
            class="width100"
            placeholder="请选择"
            size="small"
            :props="props"
            v-model="form.codeList"
            @change="handleChange"
          ></el-cascader>
        </div>
        <div class="form-item">
          <div class="form-label">
            <span class="must">*</span>
            门店类型
          </div>
          <el-select class="width100" v-model="form.hotelType" placeholder="请选择" size="small">
            <el-option v-for="item in hotelTypeOption" :key="item.k" :label="item.v" :value="item.k"> </el-option>
          </el-select>
        </div>
        <div class="form-item">
          <div class="form-label">
            <span class="must">*</span>
            门店名称
          </div>
          <el-input
            class="width100"
            v-model="form.hotelName"
            maxlength="32"
            size="small"
            placeholder="门店名称后续不可修改，请勿重复"
            :disabled="!!form.hotelId"
          ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">门店简称</div>
          <el-input
            class="width100"
            v-model="form.hotelShortName"
            maxlength="16"
            show-word-limit
            size="small"
            placeholder="请填写门店简称"
          ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">
            <span class="must">*</span>
            门店地址
          </div>
          <el-input
            class="width100"
            v-model="form.hotelAddr"
            size="small"
            placeholder="请填写门店的详细地址（除省市区外）"
          ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">导航地址</div>
          <el-input
            class="width100"
            v-model="form.hotelAddrUrl"
            size="small"
            placeholder="请粘贴门店的导航地址链接"
          ></el-input>
        </div>
        <div class="form-item width100 flex-wrap flex" style="margin-bottom: 0; margin-right: 0">
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              所属直销
            </div>
            <el-select
              class="width100"
              size="small"
              v-model="form.salerId"
              filterable
              remote
              placeholder="请选择直销人员"
              :remote-method="remoteMethod"
              :loading="selectLoading"
            >
              <el-option
                v-for="item in salerList"
                :key="item.id"
                :disabled="item.status === 2"
                :label="item.realName"
                :value="item.userId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              门店号码
            </div>
            <el-input
              class="width100"
              v-model="form.hotelPhone"
              size="small"
              placeholder="请填写门店号码，便于客户联系门店"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              状态
            </div>
            <el-select class="width100" v-model="form.status" placeholder="请选择" size="small">
              <el-option v-for="item in statusOption" :key="item.k" :label="item.v" :value="item.k"> </el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="form-label">
              <span class="must">*</span>
              是否测试门店
              <el-tooltip
                content="用于区分客户正式门店以及内部人员测试门店，新增门店时请仔细选择，后续不允许修改"
                placement="right"
              >
                <span class="icon-warning-outline"></span>
              </el-tooltip>
            </div>
            <el-select
              class="width100"
              v-model="form.testFlag"
              placeholder="请选择"
              size="small"
              :disabled="!!form.hotelId"
            >
              <el-option label="正式门店" :value="false"></el-option>
              <el-option label="测试门店" :value="true"></el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="form-label">
              <!-- <span class="must">*</span> -->
              所属集团
              <el-tooltip
                content="所属集团下有且仅有该酒店,不允许修改"
                placement="right"
                v-if="form.sonHotelCount == 1"
              >
                <span class="icon-warning-outline"></span>
              </el-tooltip>
            </div>
            <el-select
              class="width100"
              v-model="form.parentId"
              placeholder="请选择"
              size="small"
              clearable
              :disabled="form.sonHotelCount == 1"
            >
              <el-option v-for="item in brandList" :key="item.id" :label="item.hotelName" :value="item.id"> </el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item" style="margin-bottom: 0">
          <gd-map :poiId="poiId" height="200px" v-show="form.hotelAddrUrl"></gd-map>
        </div>
        <div class="form-item width100">
          <div class="form-label">备注信息</div>
          <el-input
            type="textarea"
            class="width100"
            v-model="form.remark"
            size="small"
            maxlength="255"
            rows="3"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="text-center">
        <div class="form-label">门店Logo</div>
        <img v-if="form.hotelLogo" class="upload-img" :src="form.hotelLogo" />
        <div v-else class="upload-img-empty"></div>
        <div class="upload-tips">推荐尺寸：40*40px</div>
        <upload :urls="form.hotelLogo ? [form.hotelLogo] : []" @change="handleImageChange" :showFileList="false">
          <el-button class="border-btn" plain size="small">
            <i class="el-icon-upload2"></i>
            上传Logo
          </el-button>
        </upload>
      </div>
    </div>
    <div class="page-sub-title">联系人信息</div>
    <div class="flex-form flex flex-wrap">
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          联系人姓名
        </div>
        <el-input
          class="width100"
          v-model="form.contactPerson"
          maxlength="8"
          size="small"
          placeholder="请填写真实姓名，方便他人识别"
        ></el-input>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          联系人手机号
        </div>
        <el-input class="width100" v-model="form.contactPhone" size="small" placeholder="请填写有效手机号"></el-input>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          开户人账号
        </div>
        <el-input
          class="width100"
          v-model="form.accountUsername"
          size="small"
          placeholder="用以登录门店系统"
          :disabled="!!form.hotelId"
        ></el-input>
      </div>
      <div class="form-item" v-if="!form.hotelId">
        <div class="form-label">
          <span class="must">*</span>
          初始密码
        </div>
        <el-input
          class="width100"
          v-model="form.pwd"
          size="small"
          placeholder="返回后将不再显示，请牢记密码"
          disabled
        ></el-input>
      </div>
    </div>
    <div class="page-sub-title">产品信息</div>
    <div class="flex-form flex flex-wrap">
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          所属产品
        </div>
        <el-select
          class="width100"
          v-model="form.productParentId"
          placeholder="请选择"
          size="small"
          @change="changeProduct"
          :disabled="!!form.hotelId"
        >
          <el-option
            v-for="item in productList"
            :key="item.productId"
            :label="item.productName"
            :value="item.productId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          产品版本
        </div>
        <el-select class="width100" v-model="form.productId" placeholder="请选择" size="small" @change="changeVersion">
          <el-option
            v-for="item in versionList"
            :key="item.productId"
            :label="item.versionName"
            :value="item.productId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          初装定价
        </div>
        <el-input
          class="width100"
          v-model="form.firstPrice"
          size="small"
          :placeholder="pricePlaceholder.firstPrice"
          :disabled="!(form.productParentId && form.productId)"
          @input="handleInput('firstPrice')"
        >
          <span slot="suffix" style="line-height: 32px">元</span>
        </el-input>
        <div
          class="price-tips"
          v-show="form.firstPrice && Number(form.firstPrice) < Number(pricePlaceholder.firstPrice)"
        >
          低于初装参考价
        </div>
        <div v-show="form.firstPrice && Number(form.firstPrice) < Number(pricePlaceholder.firstPrice)">
          <el-select
            class="width100 mt-10"
            size="small"
            placeholder="请选择低于参考价原因"
            v-model="form.firstPriceType"
            @change="handleReasonChange($event, 'firstPriceType')"
          >
            <el-option v-for="item in reasonOption" :key="item.k" :label="item.v" :value="item.k * 1"> </el-option>
          </el-select>
          <el-input
            v-if="form.firstPriceType == 4"
            class="width100 mt-10"
            placeholder="请输入其他原因"
            v-model="form.firstPriceRemark"
            size="small"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          年费定价
        </div>
        <el-input
          class="width100"
          v-model="form.yearPrice"
          size="small"
          :placeholder="pricePlaceholder.yearPrice"
          :disabled="!(form.productParentId && form.productId)"
          @input="handleInput('yearPrice')"
        >
          <span slot="suffix" style="line-height: 32px">元/年</span>
        </el-input>
        <div class="price-tips" v-show="form.yearPrice && Number(form.yearPrice) < Number(pricePlaceholder.yearPrice)">
          低于年费参考价
        </div>
        <div v-show="form.yearPrice && Number(form.yearPrice) < Number(pricePlaceholder.yearPrice)">
          <el-select
            class="width100 mt-10"
            size="small"
            placeholder="请选择低于参考价原因"
            v-model="form.yearPriceType"
            @change="handleReasonChange($event, 'yearPriceType')"
          >
            <el-option v-for="item in reasonOption" :key="item.k" :label="item.v" :value="item.k * 1"> </el-option>
          </el-select>
          <el-input
            v-if="form.yearPriceType == 4"
            class="width100 mt-10"
            placeholder="请输入其他原因"
            v-model="form.yearPriceRemark"
            size="small"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          初装赠送短信
        </div>
        <el-select
          class="width100"
          v-model="form.smsCount"
          placeholder=""
          size="small"
          :disabled="!(form.productParentId && form.productId) || !!form.hotelId"
        >
          <el-option value="500" label="500"></el-option>
          <el-option value="1000" label="1000"></el-option>
          <el-option value="2000" label="2000"></el-option>
          <el-option value="10000" label="10000"></el-option>
        </el-select>
        <div class="sms-tips" v-show="form.smsCount && Number(form.smsCount) > Number(gifMesNum)">
          <span class="icon-warning"></span>
          超出默认赠送条数
        </div>
        <span class="unit">条</span>
      </div>
      <div class="form-item" v-if="!form.hotelId && form.smsCount && Number(form.smsCount) > Number(gifMesNum)">
        <div class="form-label">
          <span class="must">*</span>
          赠送超过默认条数原因
          <el-tooltip content="输入初装赠送短信高于默认条数原因，以便追溯" placement="right">
            <span class="icon-warning"></span>
          </el-tooltip>
        </div>
        <el-input class="width100" v-model="form.smsRemark" size="small" maxlength="20" show-word-limit></el-input>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          启用时间
        </div>
        <el-date-picker
          class="width100"
          size="small"
          v-model="form.enableTime"
          type="date"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="form-item">
        <div class="form-label">
          <span class="must">*</span>
          到期时间
        </div>
        <el-date-picker
          class="width100"
          size="small"
          v-model="form.expireTime"
          type="date"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
          :disabled="!!form.hotelId"
        >
        </el-date-picker>
      </div>
      <div class="form-item" v-if="showRemark">
        <div class="form-label">
          <span class="must">*</span>
          备注原因
        </div>
        <el-input
          type="textarea"
          class="width100"
          v-model="form.remarkReason"
          size="small"
          maxlength="255"
          show-word-limit
        ></el-input>
      </div>
    </div>
    <div class="page-sub-title">功能模块</div>
    <div class="flex-form flex flex-wrap">
      <div class="form-item flex width100" style="line-height: 32px">
        <!-- <el-checkbox
          :value="checkModules == 'all'"
          @change="checkAll(0)"
          :indeterminate="checkModules == 'indeterminate'"
          class="mr-20"
          >全选</el-checkbox
        > -->
        <div class="flex-1">
          <div v-for="(o, i) in form.modules" :key="i" class="mb-10 flex flex-align-center">
            <el-checkbox @change="checkSingle(o, i, 0)" :value="o.isUsed">
              {{ o.moduleName }}
            </el-checkbox>

            <el-tooltip placement="top" v-if="moduleTipMap[o.moduleType] && moduleTipMap[o.moduleType].tip">
              <div slot="content">{{ moduleTipMap[o.moduleType].tip }}</div>
              <span class="icon-warning-outline ml-5"></span>
            </el-tooltip>

            <div class="flex-1 flex flex-align-center" v-if="o.moduleType === 'MEITUAN_RESV' && o.isUsed">
              <span class="ml-40 mr-10">
                <span class="must">*</span>
                商户美团id
              </span>
              <el-input size="small" class="flex-1" v-model="o.moduleContent"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <template v-if="form.hotelId == '1589851800678207489'"> -->
    <div class="page-sub-title">第三方接口</div>
    <!-- <div class="flex-form flex"> -->
    <div class="flex width100">
      <!-- <el-checkbox
        :value="thirdCheckModules == 'all'"
        @change="checkAll(1)"
        :indeterminate="thirdCheckModules == 'indeterminate'"
        class="mr-20"
        >全选
      </el-checkbox> -->
      <div>
        <div v-for="(o, i) in form.thirdModuleList" :key="i" class="third-div">
          <el-checkbox
            @change="checkSingle(o, i, 1)"
            :value="o.isUsed"
            :disabled="i == 1 && form.modules[0].isUsed == false"
          >
            {{ o.moduleName }}
          </el-checkbox>

          <el-tooltip placement="top" v-if="thirdTipMap[o.moduleType] && thirdTipMap[o.moduleType].tip">
            <div slot="content">{{ thirdTipMap[o.moduleType].tip }}</div>
            <span class="icon-warning-outline ml-5"></span>
          </el-tooltip>
          <el-button v-if="o.thirdModuleEdit === true" type="text" @click="handleSetting(o, i)">设置</el-button>
        </div>
      </div>
    </div>
    <div class="form-item m-t-16" v-if="form.thirdModuleList[0].isUsed">
      <div class="form-label">
        <span class="must">*</span>
        商户编号
      </div>
      <el-input
        class="width100"
        v-model="form.thirdModuleList[0].value"
        size="small"
        placeholder="请输入商户编号"
      ></el-input>
    </div>
    <!-- </template>   -->
    <!-- </div> -->
    <div class="text-center m-t-16 width100">
      <el-button size="small" @click="goback">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" class="m-l-90">保存</el-button>
    </div>

    <el-dialog
      title="商户授权"
      :visible.sync="mtAuthVisible"
      width="488px"
      append-to-body
      modal-append-to-body
      :close-on-click-modal="false"
    >
      <div class="text-center plr-60">
        <div class="mb-20">商户需进入授权链接进行授权操作，获得商户美团id</div>
        <el-button class="width100" size="small" type="primary" @click="copyMtLink">复制授权链接</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="thirdModuleTitle"
      :visible.sync="thirdModuleVisible"
      width="488px"
      append-to-body
      modal-append-to-body
      :close-on-click-modal="false"
      class="thirdModule"
    >
      <div class="text-center">
        <el-form ref="thirdModule" :model="thirdModuleForm" label-width="80px" :rules="thirdModuleRules">
          <el-form-item :label="o.label" :prop="o.prop" v-for="(o, i) in thirdModuleField" :key="i">
            <el-input
              v-if="o.type == 'input'"
              v-model="thirdModuleForm[o.prop]"
              :placeholder="'请输入'"
              size="small"
            ></el-input>
            <el-select
              v-if="o.type == 'select'"
              v-model="thirdModuleForm[o.prop]"
              placeholder="请选择"
              size="small"
              filterable
            >
              <el-option v-for="item in o.options" :key="item.k" :label="item.v" :value="item.k"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="thirdModuleVisible = false" size="medium">取消</el-button>
        <el-button type="primary" size="medium" @click="thirdModuleConfirm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {
  get_hotel_detail,
  add_hotel,
  edit_hotel,
  bread_brandList,
  get_meituanAuthInfo,
  get_haimiaoIsRepeat
} from '@/api/hotel'
import { get_product_list, post_getSaleUserList } from '@/api/product'
// import { get_user_list } from '@/api/user'
import { get_area_list, get_key_value } from '@/api/common'
import { FormValid } from '@/utils/formValid'
import { MathRand } from '@/utils/uuid'
import upload from '@/components/upload'
import gdMap from '@/components/gdMap'
import { objectToParams } from '@/utils'

const modulesList = [
  {
    moduleName: '零点预订',
    moduleType: 'ZERO_RESV',
    isUsed: false,
    moduleContent: ''
  },
  {
    moduleName: '美团',
    moduleType: 'MEITUAN_RESV',
    isUsed: false,
    moduleContent: ''
  }
]
const moduleTipMap = {
  ZERO_RESV: {
    tip: '支持宴会酒店做普通包厢预订业务',
    secondConfirmTip: '关闭零点预订功能后，将会影响历史零点订单查询和零点预订功能使用，请确认是否关闭？'
  },
  MEITUAN_RESV: {
    tip: '支持酒店获取美团线索',
    secondConfirmTip: '关闭美团功能后，将会影响美团线索导入功能使用，请确认是否关闭？'
  }
}
const thirdTipMap = {
  DIGUANTONG: {
    tip: '支持与滴灌通数据系统实现订单等数据的互联互通',
    secondConfirmTip: '关闭滴灌通接口后，将会影响滴灌通数据系统实现订单等数据的互联互通，请确认是否关闭？'
  },
  CASHIER: {
    tip: '支持同步收银接口的桌位及结账信息',
    secondConfirmTip: '关闭收银接口后，将会影响收银接口桌位及结账信息的同步，是否确定关闭?'
  },
  HAIMIAO: {
    tip: '支持同步嗨喵线索',
    secondConfirmTip: '关闭嗨喵接口后，将会影响嗨喵线索导入功能使用，是否确定关闭?'
  }
}
const form = {
  hotelId: '',
  provinceCode: '',
  cityCode: '',
  areaCode: '',
  codeList: [],
  hotelType: '',
  hotelName: '',
  hotelShortName: '',
  hotelAddr: '',
  hotelAddrUrl: '',
  salerId: '',
  hotelPhone: '',
  status: '2', // 默认营业中
  remark: '',
  hotelLogo: '',
  contactPerson: '',
  contactPhone: '',
  accountUsername: '',
  pwd: '',
  productParentId: '',
  productId: '',
  firstPrice: '',
  yearPrice: '',
  enableTime: '',
  expireTime: '',
  remarkReason: '',
  parentId: '',
  smsCount: '',
  smsRemark: '',
  testFlag: false,
  firstPriceType: '',
  firstPriceRemark: '',
  yearPriceType: '',
  yearPriceRemark: '',
  // 功能模块
  modules: modulesList,
  thirdModuleList: [
    {
      moduleName: '滴灌通',
      moduleType: 'DIGUANTONG',
      value: '',
      isUsed: false,
      thirdModuleEdit: false
    },
    {
      moduleName: '收银',
      moduleType: 'CASHIER',
      value: {
        username: '',
        password: '',
        cashier: ''
      },
      isUsed: false,
      thirdModuleEdit: false
    },
    {
      moduleName: '嗨喵',
      moduleType: 'HAIMIAO',
      value: {
        username: '',
        password: ''
      },
      isUsed: false,
      thirdModuleEdit: false
    }
  ]
}
export default {
  name: 'hotel-edit',
  components: {
    upload,
    gdMap
  },
  data() {
    return {
      form: this.$deepClone(form),
      formOld: {},
      hotelTypeOption: [],
      statusOption: [],
      productList: [],
      versionList: [],
      reasonOption: [],
      salerList: [],
      saler100List: [],
      selectLoading: false,
      loading: false,
      detailLoading: true,
      poiId: '',
      brandList: [],
      pricePlaceholder: {
        firstPrice: '',
        yearPrice: ''
      },
      gifMesNum: '',
      moduleTipMap: moduleTipMap,
      mtAuthVisible: false,
      meituanUrl: '',
      thirdTipMap: thirdTipMap,
      // 第三方接口
      thirdModuleTitle: '',
      thirdModuleVisible: false,
      thirdModuleForm: {
        username: '',
        password: '',
        cashier: ''
      },
      thirdModuleActiveData: {},
      thirdModuleActiveIndex: '',
      cashierOption: [],
      thirdModuleRules: {
        cashier: [{ required: true, message: '接口商家不能为空', trigger: 'change' }],
        username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    props() {
      return {
        lazy: true,
        label: 'name',
        value: 'code',
        expandTrigger: 'hover',
        lazyLoad: async (node, resolve) => {
          const { level, data } = node
          let params = {}
          if (level == 1) {
            params = {
              areaLevel: 'PROVINCE',
              code: data.code
            }
          } else if (level == 2) {
            params = {
              areaLevel: 'CITY',
              code: data.code
            }
          }
          let res = []
          if (level == 0 || (data.code && (level == 1 || level == 2))) {
            res = await get_area_list(params)
          }
          if (level == 2) {
            res = res.map((o) => {
              return {
                ...o,
                leaf: true
              }
            })
          }
          resolve(res)
        }
      }
    },
    pickerOptions() {
      return {
        disabledDate: (date) => {
          return moment(date).isBefore(moment(), 'day')
        }
      }
    },
    showRemark() {
      if (this.form.hotelId) {
        if (
          this.formOld.enableTime != this.form.enableTime ||
          this.formOld.status != this.form.status ||
          this.formOld.productId != this.form.productId
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkFormList() {
      let arr = [
        {
          label: '省市区',
          value: 'codeList',
          methods: ['notEmpty']
        },
        {
          label: '门店类型',
          value: 'hotelType',
          methods: ['notEmpty']
        },
        {
          label: '门店名称',
          value: 'hotelName',
          methods: ['notEmpty']
        },
        {
          label: '门店地址',
          value: 'hotelAddr',
          methods: ['notEmpty']
        },
        {
          label: '所属直销',
          value: 'salerId',
          methods: ['notEmpty']
        },
        {
          label: '门店号码',
          value: 'hotelPhone',
          methods: ['notEmpty']
        },
        {
          label: '状态',
          value: 'status',
          methods: ['notEmpty']
        },
        {
          label: '联系人姓名',
          value: 'contactPerson',
          methods: ['notEmpty']
        },
        {
          label: '联系人手机号',
          value: 'contactPhone',
          methods: ['notEmpty', 'isPhone']
        },
        {
          label: '开户人账号',
          value: 'accountUsername',
          methods: ['notEmpty']
        }
      ]
      if (!this.form.hotelId) {
        arr = arr.concat([
          {
            label: '初始密码',
            value: 'pwd',
            methods: ['notEmpty']
          }
        ])
      }
      arr = arr.concat([
        {
          label: '所属产品',
          value: 'productParentId',
          methods: ['notEmpty']
        },
        {
          label: '产品版本',
          value: 'productId',
          methods: ['notEmpty']
        },
        {
          label: '初装定价',
          value: 'firstPrice',
          methods: ['notEmpty', 'isInt']
        },
        {
          label: '年费定价',
          value: 'yearPrice',
          methods: ['notEmpty', 'isInt']
        },
        {
          label: '初装赠送短信',
          value: 'smsCount',
          methods: ['notEmpty', 'isInt']
        },
        {
          label: '启用时间',
          value: 'enableTime',
          methods: ['notEmpty']
        },
        {
          label: '到期时间',
          value: 'expireTime',
          methods: ['notEmpty']
        }
      ])
      if (Number(this.form.firstPrice) < Number(this.pricePlaceholder.firstPrice)) {
        arr.push({
          label: '低于参考价原因',
          value: 'firstPriceRemark',
          methods: ['notEmpty']
        })
      }
      if (Number(this.form.yearPrice) < Number(this.pricePlaceholder.yearPrice)) {
        arr.push({
          label: '低于参考价原因',
          value: 'yearPriceRemark',
          methods: ['notEmpty']
        })
      }
      if (Number(this.form.smsCount) > Number(this.gifMesNum)) {
        arr.push({
          label: '赠送超过默认条数原因',
          value: 'smsRemark',
          methods: ['notEmpty']
        })
      }
      if (this.showRemark) {
        arr = arr.concat([
          {
            label: '备注原因',
            value: 'remarkReason',
            methods: ['notEmpty']
          }
        ])
      }
      return arr
    },
    checkModules() {
      if (!Array.isArray(this.form.modules)) {
        return 'none'
      }
      let status = 'none'
      const len = this.form.modules.length
      let checkNum = 0
      for (let i = 0; i < len; i++) {
        const o = this.form.modules[i]
        if (o.isUsed) {
          checkNum++
        }
      }
      if (checkNum == len) {
        status = 'all'
      } else if (checkNum > 0 && checkNum < len) {
        status = 'indeterminate'
      }
      return status
    },
    thirdCheckModules() {
      if (!Array.isArray(this.form.thirdModuleList)) {
        return 'none'
      }
      let status = 'none'
      const len = this.form.thirdModuleList.length
      let checkNum = 0
      for (let i = 0; i < len; i++) {
        const o = this.form.thirdModuleList[i]
        if (o.isUsed) {
          checkNum++
        }
      }
      if (checkNum == len) {
        status = 'all'
      } else if (checkNum > 0 && checkNum < len) {
        status = 'indeterminate'
      }
      return status
    },
    thirdModuleField() {
      let arr = [
        {
          label: '接口商家',
          prop: 'cashier',
          type: 'select',
          options: this.cashierOption
        },
        {
          label: '用户名',
          prop: 'username',
          type: 'input'
        },
        {
          label: '密码',
          prop: 'password',
          type: 'input'
        }
      ]
      if (this.thirdModuleActiveIndex != 1) {
        arr = arr.filter((o) => o.prop != 'cashier')
      }
      return arr
    }
  },
  watch: {
    'form.hotelAddrUrl'(val) {
      const hotelAddrUrl = val.split('/')
      if (hotelAddrUrl.length > 0) {
        this.poiId = hotelAddrUrl[hotelAddrUrl.length - 1]
      }
    }
  },
  created() {
    this.detailLoading = true
  },
  async mounted() {
    await this.getProduct()
    await this.getOption()
    if (this.$route.query.id) {
      this.form.hotelId = this.$route.query.id
      await this.getDetail()
    } else {
      this.form.pwd = MathRand()
      this.form.hotelId = null
      this.detailLoading = false
      await this.getSaler()
    }

    // 美团授权链接
    get_meituanAuthInfo({
      hotelId: this.form.hotelId
    }).then((res) => {
      this.meituanUrl = objectToParams(res)
      console.log(this.meituanUrl)
    })
  },
  methods: {
    goback() {
      const keepAlive = this.$store.state.page.keepAlive.concat(['hotel-list'])
      this.$store.commit('page/SET_KEEPALIVE', keepAlive)
      this.$router.go(-1)
    },
    gobackwithReset() {
      this.$store.commit('page/SET_KEEPALIVE', [])
      this.$router.go(-1)
    },
    getOption() {
      get_key_value({
        type: 'HOTEL_TYPE'
      }).then((res) => {
        this.hotelTypeOption = res ? res.records : []
      })
      get_key_value({
        type: 'HOTEL_STATUS'
      }).then((res) => {
        this.statusOption = res ? res.records : []
      })
      // 低于参考价的原因
      get_key_value({
        type: 'LOWER_THAN_REFERENCE_PRICER_EASON'
      }).then((res) => {
        this.reasonOption = res ? res.records : []
      })
      bread_brandList({
        page: 1,
        size: 999
      }).then((res) => {
        this.brandList = res.records
      }),
        get_key_value({
          type: 'CASHIER'
        }).then((res) => {
          this.cashierOption = res ? res.records : []
          console.log(res)
        })
    },
    async getSaler(search) {
      const res = await post_getSaleUserList({
        search,
        size: 100,
        pageNo: 1,
        status: this.$route.query.id ? '' : '1'
      })
      this.selectLoading = false
      if (this.$route.query.id) {
        console.log(this.form.salerId, 555)
        let _arr = []
        res.records.map((o) => {
          if (o.status === 1) {
            _arr.push(o)
          }
          if (this.form.salerId === o.userId) {
            if (o.status === 2) {
              _arr.push(o)
            }
          }
        })
        this.salerList = _arr
      } else {
        this.salerList = res ? res.records : []
      }
      if (!search) {
        this.saler100List = this.salerList.concat()
      }
    },
    remoteMethod(query) {
      if (query) {
        this.selectLoading = true
        setTimeout(() => {
          this.getSaler(query.toLowerCase())
        }, 200)
      } else {
        this.salerList = this.saler100List.concat()
      }
    },
    getDetail() {
      get_hotel_detail({
        hotelId: this.form.hotelId
      })
        .then((res) => {
          if (res.thirdModuleList == null) {
            console.log(11)
            res.thirdModuleList = this.$deepClone(form).thirdModuleList
          }
          res.thirdModuleList.map((o) => {
            if (o.isUsed) {
              o.thirdModuleEdit = true
            }
          })
          console.log(res, 'res')
          res.modules = this.$deepClone(form).modules.map((o) => {
            const item = (res.modules || []).find((v) => v.moduleType == o.moduleType) || {}
            return {
              ...o,
              moduleContent: item.moduleContent || '',
              isUsed: item.isUsed || false
            }
          })
          this.form = Object.assign(this.form, res)

          const hotelAddrUrl = this.form.hotelAddrUrl.split('/')
          if (hotelAddrUrl.length > 0) {
            this.poiId = hotelAddrUrl[hotelAddrUrl.length - 1]
          }
          this.form.hotelId = this.form.id
          this.form.hotelType = this.form.hotelType + ''
          this.form.status = this.form.status + ''
          this.form.firstPrice = this.form.firstPrice + ''
          this.form.yearPrice = this.form.yearPrice + ''
          this.form.firstPriceType = this.form.firstPriceType == -1 ? '' : this.form.firstPriceType
          this.form.yearPriceType = this.form.yearPriceType == -1 ? '' : this.form.yearPriceType
          this.formOld = this.$deepClone(this.form)
          // 产品渲染
          for (var i = 0; i < this.productList.length; i++) {
            const o = this.productList[i]
            const index =
              o.productList &&
              o.productList.findIndex((v) => {
                return v.productId == this.form.productId
              })
            if (index > -1) {
              this.form.productParentId = o.productId
              break
            }
          }
          this.changeProduct(this.form.productParentId)
          this.changeVersion(this.form.productId)
          // 省市区渲染
          this.form.codeList = [this.form.provinceCode, this.form.cityCode, this.form.areaCode]
          this.getSaler()
          this.detailLoading = false
        })
        .catch(() => {
          this.detailLoading = false
        })
    },
    async getProduct() {
      const res = await get_product_list()
      this.productList = res
    },
    changeProduct(value) {
      console.log(value)
      const item = this.productList.find((o) => {
        return o.productId == value
      })
      if (item) {
        this.versionList = item.productList
      }
    },
    changeVersion(value) {
      const item = this.versionList.find((o) => {
        return o.productId == value
      })
      if (item) {
        this.pricePlaceholder = {
          firstPrice: item.firstPrice,
          yearPrice: item.yearPrice
        }
        this.gifMesNum = item.gifMesNum
        if (!this.form.hotelId) {
          // 新开户时才获取版本对应的默认赠送短信条数，编辑时赠送短信条数不可修改
          this.form.smsCount = item.gifMesNum
        }
      }
    },
    handleReasonChange(value, type) {
      const reason = this.reasonOption.find((o) => {
        return o.k == value
      })
      if (type == 'firstPriceType') {
        this.form.firstPriceRemark = reason.v
        if (value == 4) {
          this.form.firstPriceRemark = ''
        }
      } else if (type == 'yearPriceType') {
        this.form.yearPriceRemark = reason.v
        if (value == 4) {
          this.form.yearPriceRemark = ''
        }
      }
    },
    handleImageChange(list) {
      this.form.hotelLogo = list[0]
    },
    handleInput(val) {
      this.form[val] = this.form[val].replace(/[^\d]/g, '')
    },
    checkForm() {
      const formValid = new FormValid()
      for (const f of this.checkFormList) {
        const { label, value, methods } = f
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start()
      if (res !== true) {
        this.$message.warning(res)
        return false
      }
      if (this.form.hotelShortName.length > 16) {
        this.$message.warning('门店简称长度不可大于16位')
        return false
      }
      if (this.form.testFlag === '') {
        this.$message.warning('请选择是否为测试门店')
        return false
      }
      if (this.form.accountUsername.length > 32 || this.form.accountUsername.length < 6) {
        this.$message.warning('开户人账号必须为6-32位')
        return false
      }
      if (this.form.enableTime == moment().format('YYYY-MM-DD') && this.form.status == 1) {
        this.$message.warning('当前选择的开户时间是今天，请更改门店状态为营业中')
        return false
      }
      if (this.form.thirdModuleList[0].isUsed == true && this.form.thirdModuleList[0].value == '') {
        this.$message.warning('请输入滴灌通对应的门店code')
        return false
      }
      return {
        ...this.form
      }
    },
    async confirm() {
      let params = this.checkForm()
      if (!params) return
      params.provinceCode = params.codeList[0]
      params.cityCode = params.codeList[1]
      params.areaCode = params.codeList[2]
      delete params.productParentId
      delete params.codeList
      params.thirdModuleList.map((o) => {
        delete o.thirdModuleEdit
        if (o.moduleType == 'HAIMIAO') {
          o.value.cashier && delete o.value.cashier
        }
      })
      if (Number(params.firstPrice) >= Number(this.pricePlaceholder.firstPrice)) {
        params.firstPriceType = -1
        params.firstPriceRemark = ''
      }
      if (Number(params.yearPrice) >= Number(this.pricePlaceholder.yearPrice)) {
        params.yearPriceType = -1
        params.yearPriceRemark = ''
      }
      console.log(params)
      if (this.form.hotelId) {
        this.loading = true
        delete params.expireTime
        delete params.pwd
        delete params.accountUsername
        if (!this.showRemark) {
          delete params.remarkReason
        }
        edit_hotel(params)
          .then(() => {
            this.loading = false
            this.$message.success('保存成功')
            this.gobackwithReset()
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        const confirm = await this.$confirm('确认开户？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'dialog-class',
          center: true
        })
        if (!confirm) return
        this.loading = true
        delete params.hotelId
        delete params.remarkReason
        add_hotel(params)
          .then(() => {
            this.loading = false
            this.$message.success('保存成功')
            this.gobackwithReset()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    handleChange(val) {
      console.log(val)
    },
    checkAll(type) {
      if (type == 0) {
        if (!Array.isArray(this.form.modules)) {
          return
        }
        if (this.thirdCheckModules == 'all') {
          this.$confirm('取消后，所有功能模块将关闭，相关业务功能将停止使用，请确认是否关闭？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.form.modules.map((o) => {
                o.isUsed = false
              })
            })
            .catch(() => {})
        } else {
          this.form.modules.map((o) => {
            if (!o.isUsed && o.moduleType === 'MEITUAN_RESV') {
              this.mtAuthVisible = true
            }
            o.isUsed = true
          })
        }
      } else if (type == 1) {
        if (!Array.isArray(this.form.thirdModuleList)) {
          return
        }
        if (this.thirdCheckModules == 'all') {
          this.$confirm('取消后，所有功能模块将关闭，相关业务功能将停止使用，请确认是否关闭？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.form.thirdModuleList.map((o) => {
                o.isUsed = false
              })
            })
            .catch(() => {})
        } else {
          this.form.thirdModuleList.map((o) => {
            o.isUsed = true
          })
        }
      }
    },
    //单个勾选
    checkSingle(o, i, type) {
      if (type == 0) {
        const isUsed = this.form.modules[i].isUsed
        console.log(this.moduleTipMap[o.moduleType])
        if (isUsed) {
          if (this.moduleTipMap[o.moduleType].secondConfirmTip) {
            this.$confirm(this.moduleTipMap[o.moduleType].secondConfirmTip, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.$set(this.form.modules, i, { ...o, isUsed: !isUsed })
                if (i == 0) {
                  this.$set(this.form.thirdModuleList, 1, {
                    moduleName: '收银',
                    moduleType: 'CASHIER',
                    isUsed: false,
                    thirdModuleEdit: false,
                    value: {
                      username: '',
                      password: '',
                      cashier: ''
                    }
                  })
                }
              })
              .catch(() => {})
          } else {
            this.$set(this.form.modules, i, { ...o, isUsed: !isUsed })
          }
        } else {
          if (o.moduleType === 'MEITUAN_RESV') {
            this.mtAuthVisible = true
          }
          this.$set(this.form.modules, i, { ...o, isUsed: !isUsed })
        }
      } else if (type == 1) {
        const isUsed = this.form.thirdModuleList[i].isUsed
        console.log(this.thirdTipMap[o.moduleType])
        console.log(isUsed)
        if (isUsed) {
          if (this.thirdTipMap[o.moduleType].secondConfirmTip) {
            this.$confirm(this.thirdTipMap[o.moduleType].secondConfirmTip, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.$set(this.form.thirdModuleList, i, { ...o, isUsed: !isUsed, thirdModuleEdit: false })
              })
              .catch(() => {})
          } else {
            this.$set(this.form.thirdModuleList, i, { ...o, isUsed: !isUsed })
          }
        } else {
          if (i === 0) {
            this.$set(this.form.thirdModuleList, i, { ...o, isUsed: !isUsed })
          } else {
            this.$refs.thirdModule &&
              this.$nextTick(() => {
                this.$refs.thirdModule.clearValidate()
              })
            this.thirdModuleForm = {}
            this.thirdModuleTitle = `开通${i === 1 ? '收银' : '嗨喵'}接口`
            this.thirdModuleVisible = true
            this.thirdModuleActiveIndex = i
            this.thirdModuleActiveData = o
          }
        }
      }
    },
    copyMtLink() {
      let target = document.createElement('input')
      target.value = window.location.origin + '/#/meituanAuth?' + this.meituanUrl // 复制的内容 美团授权链接
      document.body.appendChild(target)
      target.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
    },
    handleSetting(o, i) {
      this.thirdModuleActiveIndex = i
      this.thirdModuleActiveData = o
      this.$refs.thirdModule &&
        this.$nextTick(() => {
          this.$refs.thirdModule.clearValidate()
        })
      this.thirdModuleTitle = `设置${i === 1 ? '收银' : '嗨喵'}接口`
      this.thirdModuleVisible = true
      Object.keys(o.value).map((k) => {
        this.thirdModuleForm[k] = o.value[k]
      })
    },
    thirdModuleConfirm() {
      this.$refs.thirdModule.validate((valid) => {
        if (valid) {
          if (this.thirdModuleActiveIndex == 2) {
            get_haimiaoIsRepeat({
              hotelId: this.form.hotelId,
              username: this.thirdModuleForm.username,
              password: this.thirdModuleForm.password
            })
              .then(() => {
                this.$set(this.form.thirdModuleList, this.thirdModuleActiveIndex, {
                  ...this.thirdModuleActiveData,
                  isUsed: true,
                  thirdModuleEdit: true,
                  value: {
                    username: this.thirdModuleForm.username,
                    password: this.thirdModuleForm.password,
                    cashier: this.thirdModuleForm.cashier
                  }
                })
                this.thirdModuleVisible = false
              })
              .catch((err) => {
                console.log(err)
              })
          } else {
            this.$set(this.form.thirdModuleList, this.thirdModuleActiveIndex, {
              ...this.thirdModuleActiveData,
              isUsed: true,
              thirdModuleEdit: true,
              value: {
                username: this.thirdModuleForm.username,
                password: this.thirdModuleForm.password,
                cashier: this.thirdModuleForm.cashier
              }
            })
            this.thirdModuleVisible = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-hotel {
  background: url('../../assets/icons/icon-hotel.png');
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: -1px;
  margin-right: 8px;
}
.hotel-title {
  color: #5390ff;
  font-size: 18px;
  margin-bottom: 20px;
}
.flex-form {
  width: 800px;
}
.form-item {
  margin-bottom: 16px;
  width: 45%;
  margin-right: 5%;
  position: relative;
  .must {
    color: #e14b38;
  }
  .price-tips {
    position: absolute;
    right: 50px;
    top: 30px;
    line-height: 32px;
    color: #b05656;
    font-size: 13px;
    &::before {
      content: '';
      background: url('../../assets/icons/below.png');
      display: inline-block;
      width: 10px;
      height: 14px;
      margin-right: 4px;
      vertical-align: -2px;
    }
  }
  .sms-tips {
    position: absolute;
    right: 50px;
    top: 30px;
    line-height: 32px;
    color: #b05656;
    font-size: 13px;
  }
  .unit {
    position: absolute;
    right: 30px;
    top: 30px;
    line-height: 32px;
    color: #c0c4cc;
    font-size: 13px;
  }
}
.form-label {
  padding-bottom: 10px;
  text-align: left;
}
.upload-img {
  width: 200px;
  height: 200px;
  display: block;
  margin-bottom: 10px;
}
.upload-img-empty {
  background: #f6f8fc url('../../assets/icons/icon-upload-b.png') no-repeat center center/126px 116px;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
}
.upload-tips {
  color: #c2c9d6;
  margin-bottom: 10px;
}
.width100 {
  width: 100%;
}
.m-l-90 {
  margin-left: 90px;
}
.icon-warning {
  background: url('../../assets/icons/icon-warning.png');
  display: inline-block;
  width: 14px;
  height: 15px;
  margin-right: 4px;
  vertical-align: -2px;
}
.icon-warning-outline {
  background: url('../../assets/icons/icon-warning-outline.png');
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: -1px;
}
.ml-5 {
  margin-left: 5px;
}
.third-div {
  ::v-deep .el-button {
    padding: 0 0 0 5px;
  }
  margin-bottom: 10px;
}
.thirdModule {
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-dialog__footer {
    text-align: right !important;
  }
  ::v-deep .el-button {
    min-width: 0;
  }
}
</style>