import { ajax } from "@/api/request";

export const get_product_list = function (data) {
  return ajax({
    url: '/product/v1/getProductList',
    params: data
  });
}

//获取直销列表
export const post_getSaleUserList = function (data) {
  return ajax({
    method: 'post',
    url: '/saleManage/v1/getSaleUserList',
    data: data
  });
}

//禁用直销
export const post_disableSaleUser = function (data) {
  return ajax({
    method: 'post',
    url: '/saleManage/v1/disableSaleUser',
    params: data
  });
}

//启用直销
export const post_enableSaleUser = function (data) {
  return ajax({
    method: 'post',
    url: '/saleManage/v1/enableSaleUser',
    params: data
  });
}
